import React from 'react'
import { graphql } from 'gatsby'
import useTranslations from "../hooks/useTranslations"
import { StaticImage } from "gatsby-plugin-image"

import Accordion from '../components/accordion';
import { LocaleContext } from "../components/layout"


const width = 1200
const height = 818.52
function LocationMapImage() {
  return <StaticImage src="../assets/images/als-lageplan.png" width={width} height={height} />
}

export default function KontaktPage(props) {
  const { locale } = React.useContext(LocaleContext)
  const { contactPage } = useTranslations()
  
  return (
    <>
      <div className="grid max-w-5xl gap-6 px-4 mx-auto my-8 md:px-6 md:grid-cols-2 md:gap-8 md:pt-28 md:pb-16">
        <div className="grid grid-cols-1 gap-4 md:gap-8">
          <h1 className="mb-4 text-xl md:text-3xl md:mb-8">
            {contactPage.title}
          </h1>
          <p>{contactPage.text}</p>
          <a className="max-w-xs text-center btn btn--skewed" href="&#x6d;&#x61;&#x69;&#x6c;&#x74;&#x6f;&#x3a;&#x69;&#x6e;&#x66;&#x6f;&#x40;&#x61;&#x6c;&#x73;&#x2d;&#x62;&#x6f;&#x6f;&#x74;&#x73;&#x76;&#x65;&#x72;&#x6c;&#x65;&#x69;&#x68;&#x2e;&#x64;&#x65;">E-Mail</a>
          <a className="max-w-xs text-center btn btn--skewed" href="tel:+491723250009">Tel.</a>
        </div>
        <div>
          {/* <ContactForm/> */}
        </div>
      </div>
            <div className="max-w-5xl px-4 mx-auto mb-8 delay-700 md:px-6">
                <div className="grid gap-6 md:gap-12">

                    <div className="">
                      {LocationMapImage()}
                      <div className="p-1 text-xs bg-slate-200">
                        © <a href='https://www.mapbox.com/about/maps/'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a> <strong><a href='https://www.mapbox.com/map-feedback/' target='_blank' rel='noreferrer'>Improve this map</a></strong>
                      </div>
                    </div>

                    <dl className="grid mb-4 gap-y-2 md:mb-12 gap-x-8 lg:order-2">
                        {props.data.allGoogleFaqSheet.nodes.map(node => {
                            return (
                                <Accordion
                                    key={node.id}
                                    title={locale === 'de' ? node.titleDe : node.titleEn}
                                    content={locale === 'de' ? node.textDe : node.textEn}
                                    id={node.id}
                                    open={true}
                                    group={node.group}
                                />
                            )
                        })}
                    </dl>
                </div>
            </div>
    </>
  );
}

export const pageQuery = graphql`
  query {
    heroImageSrc: file(relativePath: { eq: "images/havel-boote-mieten-jetty.jpg" }) {
      childImageSharp {
        fixed(height:800) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    marinaLanke: file(relativePath: { eq: "images/havel-boote-marina-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth:600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allGoogleFaqSheet (filter: {site: {eq: "contact"}}, sort: {fields: sortId}) {
      nodes {
        id
        titleDe
        textDe
        titleEn
        textEn
        group
        site
      }
    }
  }
`;